import React from 'react';

class Legal extends React.Component {
	render () {
		return (
      <div className='legal-container'>
        <div>
          <h1>Terms of Service</h1>
          <p>Last updated and effective date: 5 May 2024</p>
          <p>
            Welcome to <a href='spoticulum.xyz'>Spoticulum</a>, a digital service designed to create personalized photo collections based on your Spotify listening history. 
            Our service is accessed through Spotify authentication and does not store any personal data on our servers.
          </p>

          <h3>Acceptance of Terms</h3>
          <p>
            By accessing and using our website, you accept and agree to be bound by these Terms of Service and our Privacy Policy. 
            If you do not agree to these terms, please do not use our services.
          </p>

          <h3>Changes to Terms</h3>
          <p>
            We reserve the right to modify or replace these Terms at any time. Any changes will be posted on this page and we will indicate at the top of this page the date these terms were last revised. 
            Your continued use of the Service after any such changes constitutes your acceptance of the new Terms of Service.
          </p>

          <h3>Use of the Service</h3>
          <ul>
            <li><strong>Account and Security: </strong> Access to our services requires authentication through Spotify. You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password.</li>
            <li><strong>Service Limitations: </strong> Our service is dependent on the availability of the Spotify API and may be subject to limitations, delays, and other problems inherent in the use of such APIs.</li>
            <li><strong>Prohibited Uses: </strong> You agree not to misuse the service or help anyone else do so. Prohibited actions include interfering with our services or trying to access them using a method other than the interface and the instructions that we provide.</li>
          </ul>

          <h3>Intellectual Property Rights</h3>

          <p>The content provided through Spoticulum, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of 
            Spoticulum or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights.</p>

          <h3>Legal Disclaimer</h3>
          <p>Spoticulum is an independent service and is not affiliated, associated, authorized, endorsed by, or in any way officially connected with Spotify AB, or any of its subsidiaries or its affiliates. The official Spotify website can be found at https://www.spotify.com.
            The names Spotify as well as related names, marks, emblems, and images are registered trademarks of their respective owners.
            Any use of these names, trademarks, and brands on our website does not imply endorsement or partnership.</p>

          <h3>Privacy</h3>
          <p>Our Privacy Policy explains how we treat your personal data and protect your privacy when you use our Service. By using our Service, you agree that Spoticulum can use such data in accordance with our privacy policies.</p>

          <h3>Limitation of Liability</h3>
          <p>Spoticulum shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses.</p>

          <h3>Governing Law</h3>

          <p>These Terms shall be governed and construed in accordance with the laws of Germany, without regard to its conflict of law provisions.</p>

          <h3>Dispute Resolution</h3>

          <p>Any disputes arising from or relating to the subject matter of these Terms shall be finally settled by arbitration in Munich, Bavaria, Germany.</p>

          <h3>Contact</h3>
          <p>If you have any questions about these Terms, please contact at <strong>ahmetomerv@gmail.com</strong></p>
        </div>

        <br/>
        <br/>

        <div>
          <h1>Privacy Policy</h1>

          <p>
            This Privacy Policy outlines how <a href='spoticulum.xyz'>Spoticulum</a> ("we", "us", or "our") collects, uses, and protects the information of
            users ("you", "your") who visit our website and use our service. Our website allows you to create a photo collection of
            your most frequently listened-to tracks/albums on Spotify. We prioritize your privacy and are committed to protecting it through
            our compliance with this policy.
          </p>

          <h3>Information We Collect</h3>

          <p>Our service interacts with the Spotify API but does not store any of your data permanently:</p>

          <ul>
            <li>
              <strong>Spotify Data: </strong>
              When you log in through Spotify, we temporarily access your Spotify data to generate your photo collection. 
              This data includes your most played tracks and related music information. We do not store any of this data on our servers, 
              and it is processed only for the duration necessary to provide our service.
            </li>
            <li>
              <strong>Analytics Data: </strong>
              We use Google Analytics to collect anonymous information about site usage and user interaction to improve our service. 
              This may include details like your IP address, browser type, browser version, our pages that you visit, the time and date 
              of your visit, the time spent on those pages, and other diagnostic data.
            </li>
          </ul>

          <h3>How We Use Your Information</h3>

          <p>The information we collect is used in the following ways:</p>

          <ul>
            <li>
              <strong>To Provide our Service: </strong>
              We use your Spotify data to create your personalized photo collection.
            </li>
            <li>
              <strong>To Analyze and Improve our Service: </strong>
              Analytics data is used to understand how our services are used and how we can improve them.
            </li>
          </ul>

          <h3>Data Sharing and Disclosure</h3>

          <p>We do not share any personal information with third parties, except as necessary to provide the service or as required by law. Specifically:</p>

          <ul>
            <li>
              <strong>Spotify: </strong>
              Interaction with the Spotify API is governed by Spotify's own Privacy Policy.
            </li>
            <li>
              <strong>Google Analytics: </strong>
              Data collected by Google Analytics is subject to Google's Privacy Policy. We do not share identifiable personal information with Google.
            </li>
          </ul>

          <h3>Data Sharing and Disclosure</h3>
          <p>Our website does not store any personal data on our servers. All personal data processed during your interaction with our website is 
            managed through the Spotify API and is subject to Spotify’s data storage policies. We employ industry-standard measures to protect the 
            website from unauthorized access, alteration, disclosure, or destruction of any information we may hold temporarily during your session.
          </p>

          <h3>User Rights</h3>

          <p>
            You have the right to access, correct, and request the deletion of any personal data we process during your use of our website. 
            Since we do not store personal data, most of these rights can be exercised directly with Spotify. For any inquiries or requests 
            regarding data handled by our website, please contact us at <strong>ahmetomerv@gmail.com</strong>.
          </p>

          <h3>Cookies and Tracking Technologies</h3>

          <p>
            Our website uses Google Analytics to help analyze how users use the site. Google Analytics uses "cookies," which are text 
            files placed on your computer, to collect standard internet log information and visitor behavior information in an 
            anonymous form. You may refuse the use of cookies by selecting the appropriate settings on your browser, but note 
            that this may affect your ability to use the full functionality of our website.
          </p>

          <h3>International Data Transfers</h3>

          <p>
            As we use global services like Spotify and Google Analytics, your information may be processed on servers 
            located outside of the country where you live. We ensure that any data transfer complies with legal requirements 
            and that your information remains protected according to our privacy policy standards.
          </p>

          <h3>Legal Compliance</h3>

          <p>
            We comply with applicable legal requirements including the General Data Protection Regulation (GDPR) for users in the 
            European Union. This includes ensuring that data processing activities are lawful, transparent, and limited to 
            the purposes for which they are processed.
          </p>

          <h3>Changes to the Privacy Policy</h3>

          <p>
            We may update our privacy policy from time to time. We will notify you of any changes by posting the new privacy policy 
            on this page. We encourage you to review this privacy policy periodically for any changes. Changes to this privacy 
            policy are effective when they are posted on this page.
          </p>

          <h3>Contact Information</h3>

          <p>If you have any questions about this privacy policy, please contact: <strong>ahmetomerv@gmail.com</strong></p>

        </div>
      </div>
		)
	}
}

export default Legal;
